import React from "react";
import logo from "./jia-logo.svg";
import "./App.css";
import HomePage from "./Pages/HomePage/HomePage";
import Projects from "./Pages/Projects/Projects";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Component/Header";

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route path="/" Component={HomePage} />
          <Route path="/projects" Component={Projects} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
