import { NavbarContent, NavbarItem } from "@nextui-org/react";
import React from "react";
import { Navbar, NavItem, NavDropdown, Nav, Form, FormControl, Button } from 'react-bootstrap';


const NavBar:React.FC = () => {

    return (
        <Navbar bg='light' expand='lg'>
        <Navbar.Brand href="#home">
        <img src="JD.png" 
            width="50" height="50" 
            alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className='mr-auto'>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/projects">Projects</Nav.Link>
        </Nav>
        </Navbar.Collapse>
        </Navbar>
    )
}

export default NavBar;



