import React from "react";

const Projects:React.FC = () => {
    return (
        <div>
            <h1>Projects</h1>
            <p>My Projects</p>
        </div>
    )
}

export default Projects;