import React from "react";
import './HomePage.css'
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider } from "react-bootstrap";

const HomePage:React.FC = () => {
    return (
        <div className="home-page">
            <div className="home-page-content">
                <h1>Hi, I'm Jia</h1>
                <p>I'm a software engineer based in Irvine, CA.</p>
            </div>
        </div>
    )
}

export default HomePage;